import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export function acceptHeaderInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  let modifiedReq: HttpRequest<unknown>;

  if (request.method === 'PATCH') {
    modifiedReq = request.clone({
      headers: request.headers.set('Content-Type', 'application/merge-patch+json').set('Accept', 'application/ld+json'),
    });
  } else {
    modifiedReq = request.clone({
      headers: request.headers.set('Accept', 'application/ld+json'),
    });
  }

  return next(modifiedReq);
}
