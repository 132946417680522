<div id="page">
  <!-- TODO: Temporary remove -->
  <!--  <app-top-bar></app-top-bar>-->
  <app-header class="sticky-top"></app-header>
  <main id="page-content">
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
  <app-toast></app-toast>
</div>
