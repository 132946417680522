<footer class="page-footer bg-black text-white py-6 py-md-7 d-print-none">
  <div class="container gx-3 gy-5">
    <div class="row">
      <div class="col-12 md-8 col-lg-9">
        <div
          class="d-flex flex-column flex-md-row align-items-center align-items-md-end mb-3 text-center text-md-start"
        >
          <div class="mb-3">
            <img alt="Charity Booster" src="assets/images/logo-white.svg" loading="lazy" />
          </div>
          <span class="d-block ms-md-4 mb-2">Fundraising <span class="text-primary">Simplified.</span>&#8482;</span>
        </div>

        <div class="mb-4">
          <div class="row">
            <!-- MENU REPEATER: max 3 menus -->
            <div class="col-12 col-md-4 text-center text-md-start">
              <ul class="list-unstyled font-weight-normal mt-3 mb-2 my-md-4">
                @for (route of menuRoutes; track route[0]) {
                <li class="py-1">
                  <a [routerLink]="[route[0]]" class="link-inherit-primary font-weight-bold">{{ route[1] }}</a>
                </li>
                }
              </ul>
            </div>
            <!-- MENU REPEATER -->
            <!-- EXTERNAL LINKS -->
            <div class="col-12 col-md-4 text-center text-md-start">
              <ul class="list-unstyled font-weight-normal mt-3 mb-2 my-md-4">
                <li class="py-1">
                  <a class="link-inherit-primary font-weight-bold" [routerLink]="AppRoutes.Booking">Redeem</a>
                </li>
                <!--                <li class="py-1">-->
                <!--                  <a [routerLink]="[AppRoutes.Redeem]" class="link-inherit-primary font-weight-bold">Redeem</a>-->
                <!--                </li>-->
              </ul>
            </div>
            <!-- EXTERNAL LINKS -->
          </div>
        </div>
      </div>
      <div class="col-12 md-4 col-lg-3">
        <div class="mb-5 text-center">
          <p class="text-white h2 font-weight-bolder mb-2">$5,500,000</p>
          <p class="text-gray-500 h4 font-weight-light">Raised for Causes</p>
        </div>
        <div class="mb-5 text-center">
          <p class="text-white h2 font-weight-bolder mb-2">1,337+</p>
          <p class="text-gray-500 h4 font-weight-light">Charities Served</p>
        </div>
        <div class="text-center">
          <p class="text-white h2 font-weight-bolder mb-2">5,800+</p>
          <p class="text-gray-500 h4 font-weight-light">Happy Donors</p>
        </div>
      </div>
    </div>
    <div class="footer__bottom text-center text-md-start">
      <div class="row justify-content-between">
        <div class="col-12 col-md order-2 order-md-1">
          <div class="d-flex flex-column flex-md-row w-100">
            <p class="copyright order-2 order-md-1 mt-4 mt-md-0">
              © {{ currentYear }} Charity Booster, Inc. All Rights Reserved
            </p>
            <ul class="footer__links order-1 order-md-2">
              <li>
                <a [routerLink]="['/', AppRoutes.Privacy]" class="link-inherit-primary">Privacy</a>
              </li>
              <li>
                <a [routerLink]="['/', AppRoutes.Terms]" class="link-inherit-primary">Terms</a>
              </li>
              <li>Call Toll Free <a href="tel:8559333338">855.933.3338</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-auto order-1 order-md-2">
          <nav class="mb-6 mb-md-0">
            <a
              class="d-inline-block mx-3 ms-md-4 me-md-0 link-inherit-primary"
              href="https://www.facebook.com/charitybooster.co"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Facebook"
            >
              <svg fill="currentColor" height="26" viewBox="0 0 22 26" width="22" xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  d="M12.2449 23.1276V13.0626H15.0233L15.3915 9.59417H12.2449L12.2497 7.85818C12.2497 6.95356 12.3356 6.46884 13.6349 6.46884H15.3718V3H12.5931C9.25534 3 8.08053 4.68257 8.08053 7.51212V9.59457H6V13.063H8.08053V23.1276H12.2449Z"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
            <a
              class="d-inline-block mx-3 ms-md-4 me-md-0 link-inherit-primary"
              href="https://twitter.com/charitybooster"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              <svg fill="currentColor" height="26" viewBox="0 0 22 26" width="22" xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  d="M10.6959 9.93703L10.7347 10.5772L10.0877 10.4988C7.73258 10.1983 5.67506 9.17931 3.92811 7.46791L3.07405 6.61875L2.85406 7.24582C2.38821 8.64368 2.68584 10.1199 3.65637 11.1128C4.17398 11.6615 4.05752 11.7399 3.16463 11.4133C2.85406 11.3088 2.58232 11.2304 2.55644 11.2696C2.46585 11.361 2.77642 12.5499 3.02229 13.0202C3.35874 13.6734 4.04458 14.3135 4.79512 14.6924L5.4292 14.9928L4.67866 15.0059C3.954 15.0059 3.92811 15.019 4.00576 15.2933C4.26456 16.1425 5.28685 17.0439 6.42561 17.4358L7.22791 17.7102L6.52913 18.1282C5.4939 18.7292 4.2775 19.0689 3.06111 19.095C2.47879 19.108 2 19.1603 2 19.1995C2 19.3301 3.57872 20.0617 4.49749 20.3491C7.25379 21.1983 10.5277 20.8325 12.9864 19.3824C14.7333 18.3503 16.4803 16.2993 17.2955 14.3135C17.7355 13.2553 18.1755 11.3218 18.1755 10.3943C18.1755 9.79333 18.2143 9.71494 18.9389 8.99642C19.366 8.57836 19.7671 8.12112 19.8448 7.99048C19.9742 7.74226 19.9612 7.74226 19.3013 7.96435C18.2013 8.35627 18.0461 8.30402 18.5896 7.71613C18.9907 7.29808 19.4695 6.54036 19.4695 6.31827C19.4695 6.27908 19.2754 6.3444 19.0554 6.46198C18.8225 6.59262 18.3049 6.78858 17.9167 6.90616L17.2179 7.12825L16.5838 6.69713C16.2344 6.46198 15.7427 6.20069 15.4839 6.12231C14.8239 5.93941 13.8146 5.96554 13.2193 6.17457C11.6018 6.76245 10.5795 8.27789 10.6959 9.93703Z"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
            <a
              class="d-inline-block mx-3 ms-md-4 me-md-0 link-inherit-primary"
              href="https://www.linkedin.com/company/charity-booster/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Linkedin"
            >
              <svg fill="currentColor" height="26" viewBox="0 0 22 26" width="22" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" d="M5.44055 10.0725H1.24805V22.6692H5.44055V10.0725Z" fill-rule="evenodd" />
                <path
                  clip-rule="evenodd"
                  d="M5.71655 6.17583C5.68934 4.94073 4.80611 4 3.37188 4C1.93765 4 1 4.94073 1 6.17583C1 7.38535 1.90994 8.35316 3.31746 8.35316H3.34425C4.80611 8.35316 5.71655 7.38535 5.71655 6.17583Z"
                  fill-rule="evenodd"
                />
                <path
                  clip-rule="evenodd"
                  d="M20.5601 15.4466C20.5601 11.5774 18.4918 9.77661 15.733 9.77661C13.507 9.77661 12.5104 10.9993 11.954 11.857V10.0727H7.76099C7.81625 11.2548 7.76099 22.6695 7.76099 22.6695H11.954V15.6344C11.954 15.2579 11.9812 14.8824 12.0921 14.6128C12.3951 13.8607 13.085 13.082 14.2432 13.082C15.7609 13.082 16.3676 14.2371 16.3676 15.9299V22.6691H20.56L20.5601 15.4466Z"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
            <a
              class="d-inline-block mx-3 ms-md-4 me-md-0 link-inherit-primary"
              href="https://www.instagram.com/charitybooster/?hl=en"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Instagram"
            >
              <svg fill="currentColor" height="26" viewBox="0 0 22 26" width="22" xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  d="M17 15H18V22H4L4 15H5C5 18.3137 7.68629 21 11 21C14.3137 21 17 18.3137 17 15ZM7 15C7 17.2091 8.79086 19 11 19C13.2091 19 15 17.2091 15 15C15 12.7909 13.2091 11 11 11C8.79086 11 7 12.7909 7 15ZM4 6C2.89543 6 2 6.89543 2 8V22C2 23.1046 2.89543 24 4 24H18C19.1046 24 20 23.1046 20 22V8C20 6.89543 19.1046 6 18 6H4ZM18 10C18 11.1046 17.1046 12 16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C17.1046 8 18 8.89543 18 10Z"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</footer>
