<app-modal [noPadding]="true">
  <ng-template #TITLE>Session Timeout</ng-template>
  <ng-container content>
    <div class="bg-white text-center py-4 px-2 px-md-2">
      <p>You have been signed out due to inactivity. Please sign in again.</p>
    </div>
    <div class="text-center">
      <button (click)="signIn()" class="btn btn-primary px-3 w-50 mb-4 py-3 text-capitalize p-0 fs-base" type="button">
        Sign In
      </button>
    </div>
  </ng-container>
</app-modal>
