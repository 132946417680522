import { A11yModule } from '@angular/cdk/a11y';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
// import * as fromCartSelectors from '@features/cart/store/cart.selectors';
// import * as fromWishlistSelectors from '@features//wishlist/store/wishlist.selectors';
import { AuthService } from '@features/auth/auth.service';
// import { Product } from '@shared/models/product.model';
import { AccountRoute, AppRoutes } from '../../router/routes';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { MenuComponent } from '@features/profile/menu/menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [AsyncPipe, A11yModule, RouterModule, MenuComponent, HamburgerComponent, MobileNavigationComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public routes: [AppRoutes, string][] = [
    [AppRoutes.AboutUs, 'About Us'],
    [AppRoutes.HowItWorks, 'How It Works'],
    [AppRoutes.CmsPages, 'Blog'],
    [AppRoutes.FAQ, 'FAQ'],
    // [AppRoutes.Categories, 'Categories'],
    // [AppRoutes.NotFound, '404'],
    // [AppRoutes.Products, 'All Products'],
    [AppRoutes.ProductsGroups, 'Products'],
    [AppRoutes.ContactUs, 'Contact'],
  ];

  public AppRoutes: typeof AppRoutes = AppRoutes;
  public AccountRoute: typeof AccountRoute = AccountRoute;

  // public cart$: Observable<Product[]> = this.store.select(fromCartSelectors.selectCartAll);

  // public cartQuantity$: Observable<number> = this.store.select(fromCartSelectors.selectCartAllQuantity);

  // public cartPrice$: Observable<number> = this.store.select(fromCartSelectors.selectCartAllPrice);

  // public wishlistQuantity$: Observable<number> = this.store.select(fromWishlistSelectors.selectWishlistAllQuantity);

  public isMobileMenuOpen = false;

  constructor(public authService: AuthService) {}

  public toggleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  public closeMobileMenu(): void {
    this.isMobileMenuOpen = false;
  }
}
