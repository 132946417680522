/* eslint-disable no-param-reassign */
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LogoutInfoModalService } from './logout-info-modal.service';

const AUTH_HEADER = 'Authorization';

function addAuthenticationToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
  const authService = inject(AuthService);

  if (!authService.isAuthorized()) {
    return request;
  }

  return request.clone({
    headers: request.headers.set(AUTH_HEADER, `Bearer ${authService.currentUserTokenValue}`),
  });
}

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  const logoutInfoModalService = inject(LogoutInfoModalService);

  const modifiedReq: HttpRequest<unknown> = addAuthenticationToken(req);

  return next(modifiedReq).pipe(
    tap({
      error: (e: HttpEvent<unknown>) => {
        if (e instanceof HttpErrorResponse && e.status === 401) {
          authService.logout(true);

          if (e.error.message === 'JWT Token not found') {
            logoutInfoModalService.openModal();
          }
        }
      },
    })
  );
}
