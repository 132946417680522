import { StorageKeys } from '@core/storage-keys';
import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { UniversalStorage } from '@shared/utils/universal-storage.service';
import { CartState } from './state.model';

export function initCart(reducer: ActionReducer<CartState>): ActionReducer<CartState> {
  return (state: CartState, action: Action) => {
    let newState: CartState = reducer(state, action);
    if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
      const cachedState: CartState = JSON.parse(UniversalStorage.getItem(StorageKeys.CartState));

      if (cachedState) {
        newState = cachedState;
      }
    }
    return newState;
  };
}
