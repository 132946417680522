import {
  APP_ID,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import localeUS from '@angular/common/locales/en';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { acceptHeaderInterceptor } from '@core/interceptors/accept-header.interceptor';
import { authInterceptor } from '@features/auth/auth.interceptor';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MetaReducer, provideStore } from '@ngrx/store';
import { appReducer } from '@store/app.reducer';
import { provideEffects } from '@ngrx/effects';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from '@env/environment';
import { registerLocaleData } from '@angular/common';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { CartEffects } from '@features/cart/store/cart.effects';
import { initCart } from '@features/cart/store/init-cart.reducer';

registerLocaleData(localeUS);

const metaReducers: MetaReducer<any>[] = [initCart];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' })
    ),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([acceptHeaderInterceptor, authInterceptor])),
    provideStore(appReducer, {
      metaReducers,
    }),
    provideEffects(CartEffects),
    provideStoreDevtools({
      logOnly: !isDevMode(),
      maxAge: 25,
      connectInZone: true,
    }),
    importProvidersFrom(SocialLoginModule),
    { provide: APP_ID, useValue: 'app-root' },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'USD',
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-TRS6C2T',
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('763707417872743'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
  ],
};
