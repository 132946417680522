<div @slideToggleParent class="nav-wrapper">
  @if (open) {
  <div @slideToggle class="mobile-nav">
    <ul
      (clickOutside)="closeMenuHandler()"
      class="header__nav m-0 list-unstyled justify-content-center d-flex flex-column"
    >
      @for (route of routes; track route[0]) {
      <li class="nav-item text-center border-top">
        <a
          (click)="closeMenuHandler()"
          [routerLink]="[route[0]]"
          class="link-inherit-primary d-flex align-items-center p-3"
          routerLinkActive="active"
        >
          <span class="mx-auto">
            {{ route[1] }}
          </span>
          <span class="d-flex align-items-center justify-content-center">
            <svg width="8" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13l6-6-6-6" stroke="#DDD" stroke-width="2" stroke-linejoin="round" />
            </svg>
          </span>
        </a>
      </li>
      }
      <!-- <li class="position-relative align-self-center">
          <a [routerLink]="['/', AppRoutes.Wishlist]" class="btn btn-black btn-sm px-4" (click)="closeMenuHandler()"
            >Wishlist</a
          >
          <ng-container *ngIf="wishlistQuantity > 0">
            <span
              class="quantity position-absolute d-flex justify-content-center align-items-center bg-primary text-white rounded-circle"
              >{{ wishlistQuantity }}</span
            >
          </ng-container>
        </li> -->
    </ul>
  </div>
  }
</div>
