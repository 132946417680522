import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/tokens/window';

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  constructor(@Inject(WINDOW) private readonly window: Window & { fbq?: (fn: string, id: string) => {} }) {}

  public initPixel(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((f: any, b, e, v, n, t, s) => {
      if (f.fbq) {
        return;
      }
      n = f.fbq = () => {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) {
        f._fbq = n;
      }
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(this.window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    this.window.fbq('init', '412612226391183');
    this.window.fbq('track', 'PageView');
  }
}
