import { Injectable, inject } from '@angular/core';
import { CustomOverlayService } from '@core/services/overlay.service';
import { LogoutInfoModalComponent } from './logout-info-modal/logout-info-modal.component';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogoutInfoModalService {
  private overlayService = inject(CustomOverlayService);

  private logoutInfoModalOpen: boolean = false;

  public openModal() {
    if (this.logoutInfoModalOpen) {
      return;
    }

    this.logoutInfoModalOpen = true;

    this.overlayService
      .modal({
        content: LogoutInfoModalComponent,
      })
      .afterClosed$.pipe(take(1))
      .subscribe(() => {
        this.logoutInfoModalOpen = false;
      });
  }
}
