import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountRoute, AppRoutes } from '@core/router/routes';
import { environment } from '@env/environment';
import { AccountDetails } from '@features/auth/auth.models';
import { ProfileService } from '@features/profile/profile.service';
import { AppErrorHandler } from '@shared/error-handler/error-handler.model';
import { DestroySubject } from '@shared/utils/destroy-subject';
import { BehaviorSubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reserve-products-modal',
  templateUrl: './reserve-products-modal.component.html',
  styleUrls: ['./reserve-products-modal.component.scss'],
  standalone: true,
  imports: [RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReserveProductsModalComponent extends DestroySubject implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() data: AccountDetails;

  public AppRoutes: typeof AppRoutes = AppRoutes;

  public AccountRoute: typeof AccountRoute = AccountRoute;

  public staticImagesUrl: string = environment.staticImagesUrl;

  private messages$: BehaviorSubject<AppErrorHandler> = new BehaviorSubject(null);

  constructor(private profileService: ProfileService) {
    super();
  }

  public ngOnInit(): void {
    this.profileService
      .patchProfile({ extraProps: { addToEventModal: true } })
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe({
        error: (error: HttpErrorResponse) => {
          this.messages$.next(error);
        },
      });
  }

  public close(): void {
    this.closeModal.emit();
  }
}
