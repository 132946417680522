import { Routes } from '@angular/router';
import { AppRoutes } from '@core/router/routes';

export const routes: Routes = [
  { path: '', loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule) },
  {
    path: AppRoutes.Account,
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: AppRoutes.Destinations,
    loadChildren: () => import('./features/destinations/destinations.module').then((m) => m.DestinationsModule),
  },
  {
    path: `${AppRoutes.Destinations}/:${AppRoutes.DestinationSlug}`,
    loadChildren: () => import('./features/destination/destination.module').then((m) => m.DestinationModule),
  },
  {
    path: AppRoutes.Products,
    loadChildren: () => import('./features/products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: AppRoutes.ProductsGroups,
    loadChildren: () => import('@features/products-groups/product-groups.module').then((m) => m.ProductGroupsModule),
  },
  {
    path: `${AppRoutes.Products}/:${AppRoutes.ProductSlug}`,
    loadChildren: () => import('./features/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: AppRoutes.Categories,
    loadChildren: () => import('./features/categories/categories.module').then((m) => m.CategoriesModule),
  },
  {
    path: `${AppRoutes.Categories}/:${AppRoutes.CategoriesSlug}`,
    loadChildren: () => import('./features/category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: AppRoutes.CmsPages,
    loadChildren: () => import('./features/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule),
  },
  {
    path: AppRoutes.AboutUs,
    loadChildren: () => import('./features/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: AppRoutes.FAQ,
    loadChildren: () => import('./features/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: AppRoutes.ContactUs,
    loadChildren: () => import('./features/contact-us/contact-us.module').then((m) => m.ContactUsModule),
  },
  {
    path: AppRoutes.BookTrip,
    loadChildren: () => import('./features/static-booking/static-booking.module').then((m) => m.StaticBookingModule),
  },
  {
    path: AppRoutes.Covid,
    loadChildren: () => import('./features/static-covid/static-covid.module').then((m) => m.StaticCovidModule),
  },
  {
    path: AppRoutes.Privacy,
    loadChildren: () => import('./features/static-privacy/static-privacy.module').then((m) => m.StaticPrivacyModule),
  },
  // TODO: WIP, don't allow people access this page until phase 4 is ready.
  // {
  //   path: AppRoutes.Partner,
  //   loadChildren: () => import('./features/static-partner/static-partner.module').then((m) => m.StaticPartnerModule),
  // },
  {
    path: AppRoutes.HowItWorks,
    loadChildren: () => import('./features/how-it-works/how-it-works.module').then((m) => m.HowItWorksModule),
  },
  {
    path: AppRoutes.Terms,
    loadChildren: () => import('./features/static-terms/static-terms.module').then((m) => m.StaticTermsModule),
  },
  {
    path: AppRoutes.Cart,
    loadChildren: () => import('./features/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: AppRoutes.Summary,
    loadChildren: () =>
      import('./features/checkout-summary/checkout-summary.module').then((m) => m.CheckoutSummaryModule),
  },
  {
    path: AppRoutes.Booking,
    loadChildren: () => import('./features/redeem/redeem.module').then((m) => m.RedeemModule),
  },
  // {
  //   path: AppRoutes.WishlistSummary,
  //   loadChildren: () =>
  //     import('./features/wishlist-summary/wishlist-summary.module').then((m) => m.WishlistSummaryModule),
  // },
  // {
  //   path: AppRoutes.Wishlist,
  //   loadChildren: () => import('./features/wishlist/wishlist.module').then((m) => m.WishlistModule),
  // },
  {
    path: AppRoutes.NotFound,
    loadChildren: () => import('./features/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: '**',
    redirectTo: AppRoutes.NotFound,
  },
];
