import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from './cart.actions';
import { filter, fromEvent, map, of, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCartState } from './cart.selectors';
import { StorageKeys } from '@core/storage-keys';
import { UniversalStorage } from '@shared/utils/universal-storage.service';

@Injectable()
export class CartEffects {
  syncStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.upsertCart,
          CartActions.addOneCart,
          CartActions.clearCart,
          CartActions.deleteCart,
          CartActions.deleteOneCart
        ),
        withLatestFrom(this.store.select(selectCartState)),
        tap(([, cart]) => this.universalStorage.setItem(StorageKeys.CartState, JSON.stringify(cart)))
      ),
    { dispatch: false }
  );

  onChange$ = createEffect(() => {
    const event$ = typeof window !== 'undefined' ? fromEvent<StorageEvent>(window, 'storage') : of();

    return event$.pipe(
      filter((evt) => evt.key === StorageKeys.CartState && evt.newValue !== null),
      map((evt) => {
        const cart = JSON.parse(evt.newValue ?? '');
        return CartActions.setCart({ cart });
      })
    );
  });

  constructor(private actions$: Actions, private store: Store, private universalStorage: UniversalStorage) {}
}
