<header class="header bg-white border-bottom d-print-none">
  <div class="container-xl">
    <div class="row align-items-center">
      <div class="col-5 col-md-auto col-lg-2">
        <a class="d-block my-1" routerLink="/">
          <img alt="Charity Booster" class="img-fluid" src="assets/images/logo.svg" width="160" />
        </a>
      </div>
      <div class="col col-lg-10 d-flex justify-content-end">
        <ul class="header__nav m-0 list-unstyled d-none d-md-flex align-items-center">
          @for (route of routes; track route[0]) {
          <li class="ps-4">
            <a
              [routerLink]="[route[0]]"
              class="link-inherit-primary header-link d-block py-2"
              routerLinkActive="active"
              >{{ route[1] }}</a
            >
          </li>
          }
          <!--          <li class="ms-4 position-relative">-->
          <!--            <a [routerLink]="['/', AppRoutes.Wishlist]" class="btn btn-black btn-sm px-4">Wishlist</a>-->
          <!--            <ng-container *ngIf="wishlistQuantity$ | async as wishlistQuantity">-->
          <!--              <span-->
          <!--                *ngIf="wishlistQuantity > 0"-->
          <!--                class="quantity position-absolute d-flex justify-content-center align-items-center bg-primary text-white rounded-circle"-->
          <!--                >{{ wishlistQuantity }}</span-->
          <!--              >-->
          <!--            </ng-container>-->
          <!--          </li>-->
          <!--          STAGE 3 -->
          <!--          <li class="action-item">-->
          <!--            <app-header-cart-->
          <!--              [cart]="cart$ | async"-->
          <!--              [cartQuantity]="cartQuantity$ | async"-->
          <!--              [cartPrice]="cartPrice$ | async"-->
          <!--            ></app-header-cart>-->
          <!--          </li>-->
        </ul>
        <app-profile-menu />

        <div [cdkTrapFocus]="isMobileMenuOpen" class="d-md-none">
          <app-hamburger [isActive]="isMobileMenuOpen" (handleClick)="toggleMobileMenu()" />

          <app-mobile-navigation
            (closeMenu)="closeMobileMenu()"
            [isAuthorized]="authService.isAuthorized$ | async"
            [isOpen]="isMobileMenuOpen"
            [routes]="routes"
          />
        </div>
      </div>
    </div>
  </div>
</header>
