import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HamburgerComponent {
  isActive = input<boolean>();

  handleClick = output<void>();

  public toggleMenu(event: Event): void {
    event.stopPropagation();
    this.handleClick.emit();
  }
}
