<div class="position-relative ms-4">
  <button
    type="button"
    class="btn p-2 position-relative"
    aria-label="Open profile menu"
    [class.is-open]="isDropdownOpen"
    (click)="toggleDropdown($event)"
  >
    <span class="d-flex align-items-center justify-content-center">
      <svg fill="none" height="32" width="32" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z" fill="#000" />
        <path
          d="M23.434 21.994l-4.407-1.945v-2.486l.727-.775a4.525 4.525 0 001.23-3.102V9.883c0-1.81-.972-3.47-2.547-4.357a5.004 5.004 0 00-4.886 0 5.007 5.007 0 00-2.536 4.357v3.803c0 1.157.443 2.265 1.231 3.102l.726.775v2.486l-4.406 1.945c-1.255.554-2.302 1.477-3.052 2.621A13.541 13.541 0 0016 29.575c4.221 0 7.988-1.932 10.486-4.96-.75-1.144-1.797-2.067-3.052-2.621z"
          fill="#fff"
        />
        <path
          d="M16 0C7.163 0 0 7.163 0 16c0 4.172 1.612 7.95 4.222 10.806C7.15 29.994 11.335 32 16 32s8.85-2.006 11.779-5.194C30.388 23.951 32 20.172 32 16c0-8.837-7.163-16-16-16zm10.486 24.615A13.542 13.542 0 0116 29.575c-4.222 0-7.988-1.932-10.486-4.96A13.477 13.477 0 012.424 16C2.425 8.517 8.518 2.425 16 2.425c7.483 0 13.575 6.092 13.575 13.575 0 3.262-1.169 6.265-3.089 8.615z"
          fill="#000"
        />
      </svg>
    </span>
    <span *ngIf="upcomingEvents$ | async" class="upcoming-events-indicator"></span>
  </button>

  <div
    *ngIf="newProduct$ | async as newProductPath"
    @fadeInOutTop
    class="product-dropdown-wrapper d-flex flex-column justify-content-end align-items-end"
  >
    <div
      (clickOutside)="newProduct$.next(null)"
      class="product-dropdown font-weight-bold d-inline-flex end-0 bg-light rounded-lg shadow-bottom-lg mb-2 mb-md-3"
    >
      <button (click)="newProduct$.next(null)" class="btn py-3 px-4 text-capitalize" type="button">
        + Product Added
      </button>
      <a
        (click)="newProduct$.next(null)"
        [routerLink]="newProductPath"
        class="px-3 d-flex align-items-center ms-auto text-uppercase btn-open-event position-relative"
      >
        Open Event
      </a>
    </div>

    <ng-container *ngIf="accountDetails$ | async as accountDetails">
      <app-reserve-products-modal
        (closeModal)="newProduct$.next(null)"
        *ngIf="!accountDetails.extraProps?.addToEventModal"
        @fadeInOutTop
        [data]="accountDetails"
      ></app-reserve-products-modal>
    </ng-container>
  </div>

  <div
    (clickOutside)="newEvent$.next(null)"
    *ngIf="newEvent$ | async as newEventPath"
    @fadeInOutTop
    class="event-dropdown position-absolute d-flex align-items-center end-0 bg-white rounded-lg shadow-bottom-lg p-2"
  >
    <a
      (click)="newEvent$.next(null)"
      [routerLink]="newEventPath"
      class="py-2 px-4 link-black ms-auto text-primary font-weight-bold"
    >
      Open Event
    </a>
  </div>

  <div
    *ngIf="isDropdownOpen"
    @fadeInOutTop
    class="account-dropdown position-absolute end-0 bg-white rounded-lg shadow-bottom-lg py-2 overflow-auto"
    (clickOutside)="closeDropdown()"
  >
    <ul class="list-unstyled m-0">
      <ng-container *ngIf="isAuthorized$ | async; else notAuthorized">
        <li>
          <button
            (click)="closeDropdown()"
            [routerLink]="authorizedRoutes[0][0]"
            class="btn btn-link d-flex align-items-center text-capitalize fs-base py-2 px-4 menu-link-hover text-black w-100 text-start rounded-0"
          >
            {{ authorizedRoutes[0][1] }}
          </button>
        </li>
        <li>
          <button
            (click)="closeDropdown()"
            [routerLink]="authorizedRoutes[1][0]"
            class="btn btn-link d-flex align-items-center menu-link-hover py-2 px-4 text-capitalize fs-base py-2 px-4 menu-link-hover text-black w-100 text-start rounded-0"
          >
            {{ authorizedRoutes[1][1] }}
            <span *ngIf="upcomingEvents$ | async as upcomingEvents" class="upcoming-events-count ms-auto fs-sm lh-1">{{
              upcomingEvents
            }}</span>
          </button>
        </li>
        <li>
          <button
            (click)="logOut()"
            class="btn btn-link text-capitalize fs-base py-2 px-4 menu-link-hover text-black w-100 text-start rounded-0"
            type="button"
          >
            Log out
          </button>
        </li>
      </ng-container>
      <ng-template #notAuthorized>
        <li>
          <button
            (click)="signIn()"
            class="btn btn-link menu-link-hover py-2 px-4 text-black w-100 text-start rounded-0"
          >
            Login
          </button>
        </li>
        <li>
          <button
            (click)="registerCharity()"
            class="btn btn-link py-2 px-4 menu-link-hover text-black w-100 text-start rounded-0"
          >
            Create Account
          </button>
        </li>
      </ng-template>
    </ul>
  </div>
</div>
