<div class="bg-black text-white px-3 py-4 p-md-5 pe-md-7 rounded-lg shadow-bottom-lg position-relative">
  <button (click)="close()" aria-label="Close" class="close btn-close btn-close-white fs-lg" type="button"></button>

  <div class="row">
    <div class="col-12 col-md-auto">
      <div class="modal-image rounded-circle overflow-hidden ratio ratio-1x1">
        <img
          [src]="staticImagesUrl + 'assets/images/demo/event/reserve-event-modal.png'"
          alt="Don’t forget to reserve your products!"
          class="img-fluid img-cover position-absolute start-0 top-0"
        />
      </div>
    </div>
    <div class="col-12 col-md">
      <div class="pe-md-4">
        <h3 class="h4 text-white text-md-start mb-3 mt-4 mt-md-0">Don’t forget to reserve your products!</h3>

        <p class="description mb-4 lh-sm">
          Once you’re done adding all your products to an event, be sure to reserve them for use at your event. It cost
          you nothing to reserve!
        </p>

        <a [routerLink]="['/', AppRoutes.Account, AccountRoute.Events]" class="btn btn-sm btn-outline-light"
          >Go to Your Events</a
        >
      </div>
    </div>
  </div>
</div>
