import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutes } from '@core/router/routes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public AppRoutes: typeof AppRoutes = AppRoutes;

  public currentYear: number = new Date().getFullYear();

  public menuRoutes: string[][] = [
    [AppRoutes.AboutUs, 'About us'],
    // [AppRoutes.Categories, 'Categories'],
    // [AppRoutes.NotFound, '404'],
    // [AppRoutes.Products, 'All Products'],
    [AppRoutes.ProductsGroups, 'Products'],
    [AppRoutes.ContactUs, 'Contact'],
  ];
}
