import { CartState } from '@features/cart/store/state.model';
import { ActionReducerMap } from '@ngrx/store';
import * as fromCart from '../features/cart/store/cart.reducer';

export interface AppState {
  [fromCart.cartFeatureKey]: CartState;
}

export const appReducer: ActionReducerMap<AppState> = {
  [fromCart.cartFeatureKey]: fromCart.reducer,
};
